import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// style
import './style/app.css'
import './style/home.css'



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <App/>
  </>
);